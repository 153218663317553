import * as React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flex, Box, Label, Gradepoint, Select, Text, Loading } from 'basecamp';

import { toggleGpSelector } from 'features/layouts/standardPage/redux/standardPage';
import {
	getGroupSelectedTrendId,
	getAllGroupTrends,
	setGroupTrend,
	getAnalysisLoading,
	getDefualtKeyStage,
	getDefualtKeyStageOptions,
	getDefualtAcademicYear,
	getDefualtAcademicYearOptions,
	setAnalysisLoading,
} from 'features/groups/analysis/redux';
import { setCurrentKeyStage } from 'features/groups/setup/redux';
import { getGroupAccessRecords } from '../groups/setup/redux/selectors';
import {
	setContextGroupAccessId,
	getClient,
	getGroupAccessId,
	retriggerSingleSchoolPerformanceMeasures,
	triggerMatTrendSelectorSaga,
} from '../app/redux/context';
import { getGradepointIcon, getGradepointNameFromKey } from '../../utils/gradepointSelector';
import { translate } from '../../utils/locale';
import { calculateSummitKeyStage } from '../../utils/summitKeyStage';
import { academicYearLongName } from '../../utils/academicYear';
import JSON5 from 'json5';

const TrendSelector: FunctionComponent = () => {
	/**
	 ** Handle Dispatch
	 */
	const dispatch = useDispatch();

	// Get Group Trend Info
	const clientAccess = useSelector(getClient);
	const selectedTrendId = useSelector(getGroupSelectedTrendId);
	const trends = useSelector(getAllGroupTrends);
	const accessRecords = useSelector(getGroupAccessRecords);

	//Default values
	const defualtKeyStage = useSelector(getDefualtKeyStage);
	const defualtkeyStageOptions = useSelector(getDefualtKeyStageOptions);
	const defaultAcademicYearState = useSelector(getDefualtAcademicYear);
	const defaultAcademicYearOptions = useSelector(getDefualtAcademicYearOptions);

	//Group Access Id
	const groupAccessId = useSelector(getGroupAccessId);

	//Select State
	const [academicYear, setAcademicYear] = useState<any>();
	const [keyStage, setKeyStage] = useState<GradepointSelector.Filter>();
	const [dynamicKeyStageOptions, setDynamicKeyStageOptions] = useState<
		GradepointSelector.Filter[]
	>();
	const [defualtGp, setDefualtGp] = useState<number>();
	const [hideTrends, setHideTrends] = useState<boolean>(true);
	const [accessTrends, setAccessTrends] = useState<any>();

	//Handle keystage changes
	const onKeyStageChange = (select: GradepointSelector.Filter) => {
		setKeyStage(select);
		dispatch(setContextGroupAccessId(Number(select.value)));
		dispatch(setCurrentKeyStage(select));
		localStorage.removeItem('summitGp');
		setHideTrends(false);
		dispatch(triggerMatTrendSelectorSaga());
	};

	//handles academic year change
	const onAcademicYearChange = (select: any) => {
		setAcademicYear(select);
		setHideTrends(true);
		setKeyStage({ label: translate('trendSelector.DROP_DOWN_VALUE') as string, value: undefined });
		setDynamicKeyStageOptions(
			accessRecords
				.filter((y) => academicYearLongName(y.academicYear) === select.label)
				.map((x) => {
					return {
						label: calculateSummitKeyStage(x.examLevel),
						value: x.id,
					};
				})
		);
		dispatch(triggerMatTrendSelectorSaga());
	};

	/**
	 ** Handle loading state
	 */
	const loadingState = useSelector(getAnalysisLoading);
	const isLoading = [...Object.values(loadingState)].some((loading) => loading);
	//loaclly stoared keystage
	const hasKeyStageBeenSet = localStorage.getItem('keyStageStorage');

	//store the trend id in local storage
	const storageSetting = (id: number) => {
		dispatch(setGroupTrend(id));
		localStorage.setItem('summitGp', String(id));
	};

	useEffect(() => {
		setAccessTrends(
			trends.filter((x) => {
				return x.groupAccessId === clientAccess?.groupAccessId;
			})
		);

		//If a value exists in local storage use that for KS/AY value
		if (hasKeyStageBeenSet && JSON5.parse(hasKeyStageBeenSet).value !== undefined) {
			setHideTrends(false);
			setAcademicYear(
				accessRecords
					.filter((x) => x.id === JSON5.parse(hasKeyStageBeenSet).value)
					.map((x) => {
						return {
							label: academicYearLongName(x.academicYear),
							value: x.id,
						};
					})
			);

			//Use the stored groupAccessId to find the the keystage of the associated academic year
			const defaultAcademicYear = accessRecords.filter(
				(y) => y.id === JSON5.parse(hasKeyStageBeenSet).value
			);

			setDynamicKeyStageOptions(
				accessRecords
					.filter((y) => y.academicYear === defaultAcademicYear[0]?.academicYear)
					.map((x) => {
						return {
							label: calculateSummitKeyStage(x.examLevel),
							value: x.id,
						};
					})
			);
			setKeyStage(JSON5.parse(hasKeyStageBeenSet));
		}
		//If no value exists use defaults
		if (!hasKeyStageBeenSet) {
			setKeyStage(defualtKeyStage);
			setAcademicYear(defaultAcademicYearState);
			setDynamicKeyStageOptions(defualtkeyStageOptions);
			setHideTrends(false);
		}
	}, []);

	useEffect(() => {
		setAccessTrends(
			trends.filter((x) => {
				return x.groupAccessId === clientAccess?.groupAccessId;
			})
		);

		if (trends.length > 0 && !hideTrends) {
			// pick the exam results trend and set that as the default on load
			const examTrend = trends.find((trend) => trend.name === 'Exam results');
			//if there is no trend id and has an exams result trend, set as default
			if (examTrend && !localStorage.getItem('summitGp')) {
				setDefualtGp(examTrend);
				dispatch(setGroupTrend(examTrend.id));
			} else {
				//set summitgp as the default trend
				dispatch(setGroupTrend(Number(localStorage.getItem('summitGp'))));
			}
		}
	}, [trends, keyStage, clientAccess, academicYear]);

	useEffect(() => {
		if (keyStage !== undefined) {
			localStorage.setItem('keyStageStorage', JSON5.stringify(keyStage));
		}
		//If there is only one keyStage option avalible for a givin academic yeay
		//auto select that value
		if (dynamicKeyStageOptions && dynamicKeyStageOptions.length === 1) {
			onKeyStageChange(dynamicKeyStageOptions[0]);
		}
	}, [keyStage]);

	useEffect(() => {
		//If the keyStage has changed and the groupAccessId is not the same as the keyStage value
		//dispatch a new request to fetch the schools in the group
		if (
			keyStage !== undefined &&
			keyStage.value !== undefined &&
			groupAccessId !== keyStage?.value
		) {
			if (keyStage.label !== 'KS4') {
				dispatch(retriggerSingleSchoolPerformanceMeasures);
			}
		}
		//Watch for changes to the groupAccessId and keyStage
	}, [groupAccessId, keyStage, academicYear]);

	return (
		<>
			<Flex
				position="fixed"
				bg="UI.white"
				top={60}
				left={0}
				right={0}
				zIndex={999}
				dataTest="matTrendSelector"
				boxShadow="xxl"
				setAs="column"
			>
				<Flex setAs="column" withAlign="centerLeft">
					<Flex setAs="row">
						{/**
						 * Academic Year dropdown
						 */}
						{
							<Flex setAs="column" width={1} px={4} pt={2} pb={2}>
								<Text>{translate('trendSelector.ACADEMIC_YEAR')}</Text>
								<Select
									options={defaultAcademicYearOptions}
									value={academicYear}
									onChange={(select: any) => {
										onAcademicYearChange(select);
										dispatch(setAnalysisLoading('AnalysisTrends'));
									}}
									id="matTrendSelectorAcademicYearSelect"
								/>
							</Flex>
						}
						{/**
						 * KeyStage dropdown
						 */}
						{academicYear && (
							<Flex setAs="column" width={1} px={4} pt={2} pb={2}>
								<Text>{translate('trendSelector.KEY_STAGE')}</Text>
								<Select
									options={dynamicKeyStageOptions}
									value={
										dynamicKeyStageOptions?.length === 1 ? dynamicKeyStageOptions[0] : keyStage
									}
									onChange={(select: any) => {
										dispatch(setAnalysisLoading('AnalysisTrends'));
										onKeyStageChange(select);
									}}
									id="matTrendSelectorKeyStageSelect"
								/>
							</Flex>
						)}
					</Flex>
				</Flex>
				{isLoading && (
					<Flex withAlign="center">
						<Loading />
					</Flex>
				)}
				{!isLoading && !hideTrends && accessTrends.length > 0 && (
					<Label width={1} px={4} pt={2}>
						{translate('trendSelector.PLEASE_SELECT')}
					</Label>
				)}

				{!isLoading && !hideTrends && (
					<Flex setAs="row" withAlign="distribute" pl={4} flexWrap="wrap" width={1}>
						{/* Gradepoint trends */}
						{accessTrends.length
							? accessTrends.map((trend) => {
									return (
										<Box key={trend.id} flex="1 1 320px" pr={4} pb={4}>
											<Gradepoint
												withGP={getGradepointIcon(trend.gradepoints[0])}
												setAs={trend.id === defualtGp ? 'primary' : 'secondary'}
												width={1}
												onClick={() => storageSetting(trend.id)}
												disabled={isLoading}
											>
												{trend.name}
											</Gradepoint>
										</Box>
									);
							  })
							: []}
					</Flex>
				)}
				{!isLoading && !hideTrends && accessTrends.length === 0 && (
					<Flex>
						<Label width={1} px={4} pt={4} pl={4}>
							{translate('trendSelector.NO_TRENDS_AVALIBLE') as string}
						</Label>
					</Flex>
				)}

				{/* Gradepoints contained within trend */}
				{!isLoading && !hideTrends && accessTrends.length > 0 && (
					<Flex setAs="column" py={4} pl={4} width={1} bg="UI.secondary.1">
						<Label width={1}>{translate('trendSelector.SELECTED_TREND')}</Label>
						<Flex setAs="row">
							{accessTrends.length
								? accessTrends
										.filter((trend) => trend.id === selectedTrendId)
										.map((trend) =>
											trend.gradepoints.map((gp: GradepointSelector.Gradepoint) => {
												return (
													<Gradepoint
														key={gp.key.split(',')[2]}
														setAs="primary"
														withGP={getGradepointIcon(gp.key)}
														borderRadius={4}
														withShadow={false}
														mr={4}
														width={1 / 4}
														style={{ cursor: 'default' }}
														disabled={isLoading}
													>{`${getGradepointNameFromKey(gp.key)}`}</Gradepoint>
												);
											})
										)
								: []}
						</Flex>
					</Flex>
				)}
			</Flex>
			{/* Close on click overlay */}
			<Box
				position="fixed"
				top={0}
				right={0}
				bottom={0}
				left={0}
				bg="transparent"
				onClick={() => dispatch(toggleGpSelector())}
				zIndex={700}
			></Box>
		</>
	);
};

export default TrendSelector;
