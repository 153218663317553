import { SagaIterator } from 'redux-saga';
import { cancel, put, select } from 'redux-saga/effects';
import { getSelectedTrend } from 'features/groups/analysis/redux';
import { Groups } from 'features/groups/types';
import { getGradepointNameFromKey } from '../../../../../utils/gradepointSelector';
import {
	setComparisonGradepoint,
	setMainGradepoint,
	setErrorStateForGradepoint,
	setToggleAveragePointsScoreDifferenceChart,
	setSelectedSummitGradepoint,
	getSummitInitialPerformanceMeasures,
	setInitialLoading,
} from 'features/groups/analysis/redux/ks5PerformanceMeasures/slice';
import { getInitialLoading } from 'features/groups/analysis/redux/ks5PerformanceMeasures/selectors';

/**
 * Saga that dispatches the thunks required to select a gradepoint in summit
 */
export function* summitGradepointSelectorHandlerFlow(): SagaIterator<void> {
	//Get the selected trend
	// @ts-ignore
	const latestTrend: Groups.Analysis.Trends = yield select(getSelectedTrend);

	const initialLoading = yield select(getInitialLoading);

	//If the trend has failed to load, fire off this saga again and bail this thread of it -
	// hopefully it will populate by next execution
	if (latestTrend === undefined) {
		yield put(setErrorStateForGradepoint(true));
		yield cancel();
	}

	//Format the trend's gradepoint's into a select option array
	const filterOptions: Array<CommonUiTypes.SelectOption> = latestTrend?.gradepoints.map((gp) => {
		return {
			label: getGradepointNameFromKey(gp.key),
			value: gp.key,
		};
	});
	//When less than 1 or null, bail
	if (filterOptions == undefined || filterOptions.length === 0) {
		yield put(setErrorStateForGradepoint(true));

		yield cancel();
	}

	//populate the gradepoint dropdown
	yield put(setSelectedSummitGradepoint(filterOptions));

	//If there is only 1 gradepoint available, only populate the first dropbeardown
	if (filterOptions.length === 1) {
		yield put(setMainGradepoint(filterOptions[0]));
		yield put(setComparisonGradepoint({ label: '', value: '' } as CommonUiTypes.SelectOption));
		yield put(setToggleAveragePointsScoreDifferenceChart(false));
		yield put(setErrorStateForGradepoint(false));
	}

	//if there are more than 1 comparison's available
	if (filterOptions.length > 1) {
		yield put(setMainGradepoint(filterOptions[filterOptions.length - 1]));
		yield put(setToggleAveragePointsScoreDifferenceChart(true));
		yield put(setErrorStateForGradepoint(false));
		yield put(setComparisonGradepoint(filterOptions[filterOptions.length - 2]));
	}

	//only do this first time
	if (initialLoading) {
		yield put(setInitialLoading(false));
	}

	yield cancel();
}
