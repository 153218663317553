import { getAllGradepoints, getAppliedGradepoints } from 'features/app/redux/context';
import { theme } from 'basecamp';
import { getMetricIdsFromEnum, Ks4PmNameRenderer } from '../../../../utils/performanceMeasures';
import moment from 'moment';

// Get Loading
export const fetchLoading = (context: RootState): boolean =>
	context.performanceMetrics.loading || context.performanceMetrics.loadingComparisons;

// Get all metrics
export const getAllMetrics = (context: RootState) => context.performanceMetrics.metrics;

// Has the user got calculated metrics available to view?
export const hasMetrics = (context: RootState): boolean => {
	const allMetrics = getAllMetrics(context);
	const mainGp = getMainGradepointId(context);

	return !!allMetrics && allMetrics.filter((metric) => metric.gradepointId === mainGp).length > 0;
};

//get the date for the current primary gradepoint
export const getTheCalcualtedDate = (gp: any) => (context: RootState) => {
	const store = context.performanceMetrics.metrics;
	const date = store?.filter((x) => x.gradepointId === gp).map((x) => x.dateCreated);

	return date;
};

//is new data avalibale
export const dataHasRecalced = (context: RootState): boolean => {
	return context.performanceMetrics.hasNewData;
};

export const idForUpdatedData = (context: RootState): any => {
	return context.performanceMetrics.updatedGpId;
};

export const getAvailablePerformanceComparisons = (state: RootState) => {
	return Object.values(
		(state.performanceMetrics as PerformanceMetrics.State).availableComparisons.reduce(
			(obj, comparison) => {
				const existing = obj[comparison.comparisonId] ?? [];

				return {
					...obj,
					[comparison.comparisonId]: [...existing, comparison],
				};
			},
			{}
		)
	).map((comparisons: PerformanceMetrics.Comparison[]) => {
		return {
			label: comparisons[0].comparisonDisplay,
			comparisonId: comparisons[0].comparisonId,
			options: comparisons.reverse().map((comparison) => ({
				label: comparison.comparisonValueDisplay,
				value: `${comparison.comparisonId}|${comparison.comparisonValueId}`,
			})),
		};
	});
};

export const getAppliedPerformanceComparisonsForThunk = (state: RootState) => {
	return Object.values(
		(state.performanceMetrics as PerformanceMetrics.State).appliedComparisons.reduce(
			(obj, comparison) => {
				const existing = obj[comparison.comparisonId] ?? [];

				return {
					...obj,
					[comparison.comparisonId]: [...existing, comparison],
				};
			},
			{}
		)
	).map((comparisons: PerformanceMetrics.Comparison[]) => {
		return {
			ComparisonId: comparisons[0].comparisonId,
			ComparisonValueId: comparisons.map((c) => c.comparisonValueId),
		};
	});
};

export const getAppliedPerformanceComparisons = (state: RootState) => {
	return (state.performanceMetrics as PerformanceMetrics.State).appliedComparisons.map((c) => ({
		label: c.comparisonDisplay,
		value: `${c.comparisonId}|${c.comparisonValueId}`,
	}));
};

// Get formatted options for comparisons select in spo pms
export const getAvailableComparisons = (state: RootState) => {
	// Get the metrics
	const metrics = getAllMetrics(state);
	// Get a list of unique comparsion ids
	const getUniqueMetricIds = [...new Set(metrics?.map((metric) => metric.comparisonId))];
	// Get a list of unique comparison names
	const getUniqueMetricNames = [...new Set(metrics?.map((metric) => metric.comparisonName))];
	// return formatted comparison options list
	return getUniqueMetricIds
		.map((id, i) => {
			return {
				label: getUniqueMetricNames[i],
				value: id,
			};
		})
		.filter((option) => option.value !== 0);
};

// Get formatted options for comparisons
export const getAvailableComparisonGroups = (state: RootState) => {
	const metrics = getAllMetrics(state);
	const getAllUniqueMetrics = [...new Set(metrics?.map((metric) => metric.comparisonName))];

	const filteredMetrics = getAllUniqueMetrics.map((m) => {
		return metrics!.filter((metric) => metric.comparisonName === m);
	});

	return filteredMetrics.map((fm, i) => {
		const displays = [...new Set(fm.map((metric) => metric.comparisonDisplay))];
		const comparisonIds = [...new Set(metrics?.map((metric) => metric.comparisonId))];
		const values = [...new Set(fm.map((metric) => metric.comparisonValue))];

		return {
			label: getAllUniqueMetrics[i],
			options: displays.map((display, j) => {
				return {
					label: display,
					value: `${getAllUniqueMetrics[i]}|${values[j]}`,
				};
			}),
			comparisonId: comparisonIds[i],
		};
	});
};

// Get Table Data
export const getKs4PmTableData = (metricSet: any, toggle?: boolean) => (context: RootState) => {
	// Get the metrics
	const allMetrics = getAllMetrics(context);

	// Get Applied Main Gradepoint
	const mainGpId = getMainGradepointId(context);

	// Get the metric ids used in the summary table
	const ids = getMetricIdsFromEnum(metricSet);

	// Get a filtered metric set
	const filteredMetrics = ids.map((id) =>
		allMetrics?.filter((metric) => metric.metricId === id && metric.gradepointId === mainGpId)
	);

	// Create the summary table data
	const metrics = filteredMetrics.map((fm) => {
		return fm?.reduce((acc, curr) => {
			// Unknown, Yes, and No are used across multiple comparisons so we need to append the comparison name to make it unique
			const formatComparisonName = ['Unknown', 'Yes', 'No'].includes(curr.comparisonDisplay)
				? `${curr.comparisonName}-${curr.comparisonDisplay}`
				: curr.comparisonDisplay;

			// Handle nulls and replace spaces with dashes
			const comparisonDisplay = !!formatComparisonName
				? formatComparisonName.toLowerCase().replace(/\./g, '-').replace(/\s+/g, '-')
				: formatComparisonName;

			let metricToDisplay = toggle != null && toggle ? curr.metricValue : curr.metricPercentage;

			//Final check; if there is no toggle on the table, we use the value given not precentage
			if (toggle == null) {
				metricToDisplay = curr.metricValue;
			}

			return {
				...acc,
				'metric-display-name': Ks4PmNameRenderer(curr.metricId, curr.metricDisplayname, toggle),
				/**
				 * Pass the value as an avg / agg or a  percentage
				 * Metric id is used for display purposes, see src/utils/performanceMeasures.ts
				 */
				[comparisonDisplay]: !!metricToDisplay ? metricToDisplay : '-',
			};
		}, {});
	});

	// Get available comparison list
	const comparisons: { [key: string]: number } = getAvailableComparisons(context).reduce(
		(acc, curr) => {
			return {
				...acc,
				[curr.label.toLowerCase()]: curr.value,
			};
		},
		{ all: 0 }
	);

	// Set desired column def order
	const colDefOrder = [
		comparisons['all'],
		comparisons['gender'],
		comparisons['disadvantaged'],
		comparisons['send'],
		comparisons['eal'],
	];

	// Get column defs
	const colDefs: PerformanceMetrics.ColDefs =
		filteredMetrics[0]?.map((fm) => {
			// Unknown, Yes, and No are used across multiple comparisons so we need to append the comparison name to make it unique
			const common = ['Unknown', 'Yes', 'No'].includes(fm.comparisonDisplay);
			const formatFieldName = common
				? `${fm.comparisonName}-${fm.comparisonDisplay}`
				: fm.comparisonDisplay;

			const headerName = (common
				? `${fm.comparisonName} - ${fm.comparisonDisplay}`
				: fm.comparisonDisplay
			).replace('Disadvantaged', 'Disadv');

			// Handle nulls and replace spaces with dashes
			const field = formatFieldName.toLowerCase().replace(/\./g, '-').replace(/\s+/g, '-');

			return {
				headerName: headerName,
				field,
				width: 100,
				pinned: field === 'all',
				cellStyle: () => {
					return field === 'all' ? { backgroundColor: theme.colors.UI.secondary[2] } : {};
				},
				valueGetter: (params) => {
					return params.data[field] !== '-'
						? Number(params.data[field]).toFixed(2)
						: params.data[field];
				},
				cellClass: field === 'all' ? 'all' : '',
				comparisonId: fm.comparisonId,
				comparisonValueId: fm.comparisonValueId,
			};
		}) || [];

	const orderedColDefs = colDefOrder.flatMap((order) => {
		return colDefs?.filter((colDef) => colDef.comparisonId === order).reverse();
	});

	return {
		metrics,
		colDefs: [
			{
				headerName: '',
				field: 'metric-display-name',
				cellRenderer: 'ks4PmNameRenderer',
				minWidth: 350,
				pinned: 'left',
			},
			...orderedColDefs,
		],
	};
};

// Get chart data
export const getKs4PmChartData = (metricId: number) => (context: RootState) => {
	// Get the metrics
	const metrics = getAllMetrics(context);

	// Get all gradepoints
	const allGradepoints = getAllGradepoints(context);

	// Comparison Gradepoint
	const appliedComparisonId = getAppliedComparison(context).value;

	// Main Gradepoint
	const mainGradepoint = getMainGradepointId(context);

	// Comparison Gradepoint
	const comparisonGradepointList = getComparisonGradepointIdList(context);

	// Get a filtered metric set
	const filteredMetrics = metrics?.filter(
		(metric) =>
			// Gets the correct metric e.g. EBacc APS
			metric.metricId === metricId &&
			// Filter comparison name 'All' and applied comparison
			(metric.comparisonId === 0 || metric.comparisonId === appliedComparisonId)
	);

	const yAxisValues = filteredMetrics?.map((x) => x.metricValue).sort((a, b) => a - b);

	// remove any duplicate values
	const yAxis = [...new Set(yAxisValues)];

	// Get the comparison names for the xAxis
	const xAxisValues = filteredMetrics
		?.filter(
			(fm) =>
				fm.gradepointId === mainGradepoint ||
				comparisonGradepointList.some((id) => id.key === fm.gradepointId)
		)
		.map((fm) => fm.comparisonDisplay);

	// Remove any duplicates
	const xAxis = [...new Set(xAxisValues)];

	const getComparisonGpNames = () => {
		return comparisonGradepointList.map((m) => {
			return { name: allGradepoints.find((gp) => gp.gradepointId === m.key)?.name || '' };
		});
	};

	function getComparisonMetrics() {
		const a = comparisonGradepointList.map((x) => {
			return filteredMetrics?.filter((f) => {
				return f.gradepointId === x.key;
			});
		});

		return a;
	}

	// Return chart y axis and metric data grouped by gradepoint
	return {
		xAxis,
		legend: [
			...getComparisonGpNames(),
			...[{ name: allGradepoints.find((gp) => gp.gradepointId === mainGradepoint)?.name || '' }],
		],
		metrics: [
			...getComparisonMetrics(),
			filteredMetrics?.filter((fm) => fm.gradepointId === mainGradepoint) || [],
		],
		yMin: Math.floor(yAxis[0]), // round down so the lowest number displays
		yMax: Math.ceil(yAxis[yAxis.length - 1]), // round up so the highest number displays
	};
};
// Get all KS4 gradepoints by type e.g. inyear, endofyear
export const getAllKs4GradepointsByType = (type?: string, recent: boolean = false) => (
	context: RootState
) => {
	// Get all available gradepoints
	const allGradepoints = getAllGradepoints(context);

	let moments = allGradepoints.map((x) => moment(x.lastUpdated)),
		maxDate = moment.max(moments);

	const whichIsRecent = allGradepoints.filter(
		(x) => x.type !== 'EndOfYear' && moment(x.lastUpdated)._i === maxDate._i
	);

	const yearGroupsToDisplay = [9, 10, 11];

	const getAllKs4Gradepoints = allGradepoints.filter((gp) =>
		gp.meta?.yearGroups.some((group: number) => yearGroupsToDisplay.includes(group))
	);

	// Filter KS4 gps by type
	const getKs4GradepointsByType = getAllKs4Gradepoints
		.filter((gradepoint) => gradepoint.type.toLowerCase() === type)
		.sort((a, b) => {
			return type === 'endofyear' ? b.year - a.year : b.order! - a.order!;
		});

	// If a type is passed return filtered list, otherwise return all KS4 gradepoints
	return recent ? whichIsRecent : !!type ? getKs4GradepointsByType : allGradepoints;
};

// Format available gradepoints for React Select
export const formatGradepointOptions = (type?: string) => (context: RootState) => {
	const gradepoints = getAllKs4GradepointsByType(type)(context);

	return gradepoints.map((gradepoint) => ({
		label: gradepoint.name,
		value: gradepoint.gradepointId,
	}));
};

// Get gradepoint options
export const getGradepointOptions = (
	context: RootState
): Array<{ label: string; options: PerformanceMetrics.AppliedGp[] }> => {
	// Return formatted gradepoint options with added titles
	return [
		{
			label: 'Exam Results',
			options: [...formatGradepointOptions('endofyear')(context)],
		},
		{
			label: 'Monitoring Points',
			options: [...formatGradepointOptions('inyear')(context)],
		},
	];
};

// Get applied comparison
export const getAppliedComparison = (context: RootState) => {
	return context.performanceMetrics.appliedComparison;
};

//Get Comparison Gradepoint Id list(no key)
export const getComparisonGradepointId = (context: RootState) => {
	const appliedGradepoints = getAppliedGradepoints(context);
	const keys = appliedGradepoints.filter((x) => x.isPrimary === false).map((t) => t.gradepointId);
	return keys;
};

// Get Comparison Gradepoint Ids
export const getComparisonGradepointIdList = (context: RootState) => {
	const appliedGradepoints = getAppliedGradepoints(context);
	const keys = appliedGradepoints
		.filter((x) => x.isPrimary === false)
		.map((t) => ({ key: t.gradepointId }));
	return keys;
};

// Get Main Gradepoint Id
export const getMainGradepointId = ({ context: { reportContext } }: RootState) => {
	return reportContext.currentDataset?.primaryGradepoint?.gradepointId;
};

/**
 * Student Metrics table
 */
// Get Student Metrics Data
// TODO - remove after testing
export const getStudentMetrics = (context: RootState) => context.performanceMetrics.studentMetrics;

// Get Loading
// TODO - remove after testing
export const studentMetricsIsLoading = (context: RootState): boolean =>
	context.performanceMetrics.studentMetricsLoading;

// Get Student Metrics Data
// TODO - remove after testing
export const getStudentMetricComparison = (context: RootState) =>
	context.performanceMetrics.studentMetricComparison;

// Get Student Metrics Table Data
// TODO - remove after testing
export const getStudentMetricsTableData = (context: RootState) => {
	// Get the metrics
	const metrics = getStudentMetrics(context);

	// Get Applied Main Gradepoint
	const mainGpId = getMainGradepointId(context);

	// Get Applied Student Metric Comparison
	// TODO - remove after testing
	//const studentMetricComparison = getStudentMetricComparison(context);

	// Get all unique student ids
	const getAllUniqueStudentIds = [...new Set(metrics?.map((metric) => metric.studentEntryId))];

	// Get a filtered metric set
	const filteredMetrics = getAllUniqueStudentIds
		?.map((id) =>
			metrics?.filter((metric) => metric.studentEntryId === id && metric.gradepointId === mainGpId)
		)
		.filter((m) => m?.length !== 0);

	const studentMetrics = filteredMetrics?.map((fm) => {
		return fm?.reduce((acc, curr) => {
			const key = `${curr.metricDisplayname}-${curr.comparisonName}-${curr.comparisonDisplay}`;
			const formattedKey = key.replace(/\./g, '-').replace(/\s+/g, '-');

			return {
				...acc,
				'student-id': curr.studentEntryId,
				'student-name': curr.studentName,
				/**
				 * Pass the value as an avg / agg or a  percentage
				 * Metric id is used for display purposes, see src/utils/performanceMeasures.ts
				 */
				[formattedKey]: curr.metricValue.toString(),
			};
		}, {});
	});

	return studentMetrics;
};

// Get Student Metric Dynamic Column Defs
// TODO - remove after testing
export const getStudentMetricColDefs = (context: RootState) => {
	// Get the metrics
	const metrics = getStudentMetrics(context);

	// Get applied comparison id
	const appliedComparison = getStudentMetricComparison(context).value;

	// Get all unique metric names
	const metricNames = metrics && [...new Set(metrics.map((metric) => metric.metricDisplayname))];

	// Get applied comparison name
	const comparisonName = metrics?.find((metric) => metric.comparisonId === appliedComparison)
		?.comparisonName;

	// Get all unique comparison value names
	const comparisonValueNames = metrics && [
		...new Set(metrics.map((metric) => metric.comparisonDisplay)),
	];

	const colDefs = metricNames?.map((metricName) => {
		return {
			headerName: `${metricName} - ${comparisonName}`,
			children:
				comparisonValueNames?.map((valueName) => {
					const key = `${metricName}-${comparisonName}-${valueName}`;
					const field = key.replace(/\./g, '-').replace(/\s+/g, '-');
					const minWidth = comparisonValueNames.length > 1 ? 150 : 400;

					return {
						headerName: valueName,
						field,
						minWidth,
					};
				}) || [],
		};
	});

	return colDefs || [];
};

export const getProgress8Measures = (state: RootState) => {
	return state.performanceMetrics.progress8Measures;
};

/**
 * Gets state of ks4 pm toggles
 * @param state Redux state
 * @returns bool
 */
export const getSummaryToggle = (state: RootState) => {
	return state.performanceMetrics.summaryToggle;
};
export const getGrade4Toggle = (state: RootState) => {
	return state.performanceMetrics.grade4Toggle;
};
export const getGrade5Toggle = (state: RootState) => {
	return state.performanceMetrics.grade5Toggle;
};
export const getEntriesBaccToggle = (state: RootState) => {
	return state.performanceMetrics.entriesBaccToggle;
};
export const getEngMathsToggle = (state: RootState) => {
	return state.performanceMetrics.engMathsToggle;
};
