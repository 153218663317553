import { SagaIterator } from 'redux-saga';
import { cancel, put, retry, select } from 'redux-saga/effects';
import { sagaFlowConfigurations } from '../../../../constants';
import { getGroupAccessId, setActiveChildSchool, setSchoolsInGroupError } from '../context';
import { getSchoolsByGroupAccessId } from '../../../../api/contextAPI';
import { setSchoolsInGroup, hasGroupAccess } from '../context';
import { getFeature } from '../features';
import { translate } from '../../../../../src/utils/locale';
import { getAllGroupTrends } from 'features/groups/analysis/redux';
/**
 * Saga that fetches a list of schools in a group
 */
export function* schoolsInGroupFlow(): SagaIterator<void> {
	//get the group access id
	const groupAccessId = yield select(getGroupAccessId) ?? 0;

	//Is the current account a MAT account
	const isMat = yield select(hasGroupAccess);

	const trends = yield select(getAllGroupTrends);

	//Is the feature flag enabled for the current account
	const featureFlag = yield select(getFeature('summit_ks5'));

	//if the feature flag is disabled, cancel the saga
	if (!featureFlag) {
		//bail out of the saga
		yield cancel();
	}

	//The account is not a MAT
	if (!isMat) {
		//bail out of the saga
		yield cancel();
	}

	//The account is a Mat but something is wrong with the group access id
	if (groupAccessId === 0) {
		//bail out of the saga
		yield cancel();
	}

	if (trends === undefined || trends.length === 0) {
		//bail out of the saga
		yield cancel();
	}

	//get the schools in the group
	const schoolsInGroup = yield retry(
		sagaFlowConfigurations.apiRetry,
		sagaFlowConfigurations.milliSeconds,
		getSchoolsByGroupAccessId,
		groupAccessId
	);

	//if there is no error, set the schools in the group
	if (!schoolsInGroup.error) {
		//set the schools in the group
		yield put(setSchoolsInGroup(schoolsInGroup.responseObject));

		//set the active child school - default to the first school in the list
		//This will be updated later either from a click through from the overview or from the nav
		yield put(setActiveChildSchool(schoolsInGroup.responseObject[0]));

		//bail out of the saga
		yield cancel();
	}

	//set the error message
	yield put(
		setSchoolsInGroupError(
			translate('groups.ks5Pm.singleSchoolPerformanceMeasures.common.ERROR_MESSAGE') as string
		)
	);

	//bail out of the saga
	yield cancel();
}
