import { Context } from 'features/app/types';
import { translate } from '../../../utils/locale';

const isVisible = (p: string, path: string): boolean => !path.includes(p);

/**
 ** Connect Routes
 */
export const connectRoot: (
	path: string,
	isLoading: boolean,
	canViewKS4SubjectPages: boolean,
	canViewALevelPerfMeasures: boolean,
	canViewStrategicAnalysis: boolean,
	canViewOldStrategicAnalysis: boolean,
	canViewIBPerfMeasures: boolean
) => PrimaryNavigation.Route[] = (
	path,
	isLoading,
	canViewKS4SubjectPages,
	canViewALevelPerfMeasures,
	canViewStrategicAnalysis,
	canViewOldStrategicAnalysis,
	canViewIBPerfMeasures
) => {
	return [
		{
			name: translate('primaryNavigation.connect.root.STRATEGIC') as string,
			path: '/strategic',
			isDisabled: isLoading,
			isVisible: canViewOldStrategicAnalysis && isVisible('/strategic', path),
			type: 'root',
		},
		{
			name: translate('primaryNavigation.connect.root.STRATEGIC_ANALYSIS') as string,
			path: '/strategic-analysis',
			isDisabled: isLoading,
			isVisible: canViewStrategicAnalysis && isVisible('/strategic-analysis', path),
			type: 'root',
		},
		{
			name: translate('primaryNavigation.connect.root.SUBJECT_ANALYSIS') as string,
			path: '/subjects',
			isDisabled: isLoading,
			isVisible: isVisible('/subjects', path),
			type: 'root',
		},
		{
			name: translate('primaryNavigation.connect.root.STUDENT_ANALYSIS') as string,
			path: '/students',
			isDisabled: isLoading,
			isVisible: isVisible('/students', path),
			type: 'root',
		},
		{
			name: translate('primaryNavigation.connect.root.MONITORING') as string,
			path: '/monitoring',
			isDisabled: isLoading,
			isVisible: isVisible('/monitoring', path),
			type: 'root',
		},
		{
			name: translate('primaryNavigation.connect.root.KS4_PERFORMANCE_MEASURES') as string,
			path: '/performance-measures/attainment-measures',
			isDisabled: isLoading,
			isVisible: canViewKS4SubjectPages && isVisible('/performance-measures', path),
			type: 'root',
		},
		{
			name: 'KS5 Performance Measures',
			path: '/Ks5PerformanceMeasures/Alevel',
			isDisabled: isLoading,
			isVisible: canViewALevelPerfMeasures,
			type: 'root',
		},
		{
			name: 'IB Performance Measures',
			path: '/ib-performance-measures',
			isDisabled: isLoading,
			isVisible: canViewIBPerfMeasures && isVisible('/ib-performance-measures', path),
			type: 'root',
		},
	];
};

/**
 ** Summit Routes
 */
const base = '/groups';
export const summitRoot: (
	path: string,
	isSummitPmFeatureFlagEnabled: boolean,
	defualtSelectedSchools: Context.ArrayOfSchools | undefined
) => PrimaryNavigation.Route[] = (path, isSummitPmFeatureFlagEnabled, defualtSelectedSchool) => {
	return !!defualtSelectedSchool
		? [
				{
					name: translate('primaryNavigation.summit.root.STRATEGIC') as string,
					path: `${base}/strategic`,
					isDisabled: false,
					isVisible: isVisible(`${base}/strategic`, path),
					type: 'groupsRoot',
				},
				{
					name: translate('primaryNavigation.summit.root.BBPA') as string,
					path: `${base}/bbpa`,
					isDisabled: false,
					isVisible: isVisible(`${base}/bbpa`, path),
					type: 'groupsRoot',
				},
				{
					name: translate('primaryNavigation.summit.root.SUBJECTS_ANALYSIS') as string,
					path: `${base}/subjects`,
					isDisabled: false,
					isVisible: isVisible(`${base}/subjects`, path),
					type: 'groupsRoot',
				},
				//Overview of Providers in Group (all schools in group - overview)
				{
					name: 'Overview of Providers in Group',
					path: `${base}/ks5pm/group-overview`,
					isDisabled: false,
					isVisible:
						isSummitPmFeatureFlagEnabled && isVisible(`${base}/ks5pm/group-overview`, path),
					type: 'groupsRoot',
				},
				//Summary of Individual Providers (schools in group)

				{
					name: 'Provider Level Summary',
					path: `${base}/ks5pm/${encodeURIComponent(defualtSelectedSchool.childSchoolName)}`,
					isDisabled: false,
					isVisible: isSummitPmFeatureFlagEnabled && isVisible(`${base}/ks5pm/:groupKs5Pms`, path),
					type: 'groupsRoot',
				},
		  ]
		: [
				{
					name: translate('primaryNavigation.summit.root.STRATEGIC') as string,
					path: `${base}/strategic`,
					isDisabled: false,
					isVisible: isVisible(`${base}/strategic`, path),
					type: 'groupsRoot',
				},
				{
					name: translate('primaryNavigation.summit.root.BBPA') as string,
					path: `${base}/bbpa`,
					isDisabled: false,
					isVisible: isVisible(`${base}/bbpa`, path),
					type: 'groupsRoot',
				},
				{
					name: translate('primaryNavigation.summit.root.SUBJECTS_ANALYSIS') as string,
					path: `${base}/subjects`,
					isDisabled: false,
					isVisible: isVisible(`${base}/subjects`, path),
					type: 'groupsRoot',
				},
				//Overview of Providers in Group (all schools in group - overview)
				{
					name: 'Overview of Providers in Group',
					path: `${base}/ks5pm/group-overview`,
					isDisabled: false,
					isVisible:
						isSummitPmFeatureFlagEnabled && isVisible(`${base}/ks5pm/group-overview`, path),
					type: 'groupsRoot',
				},
		  ];
};
