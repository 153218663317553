import { takeLatest } from 'redux-saga/effects';
import { fetchSubjectsTakenFlow } from './fetchSubjectsTakenflow';
import { schoolsInGroupFlow } from './schoolsInGroupFlow';

/**
 * Export all Context Sagas in one array
 * This is imported into a root saga in the store.
 * Root saga is effectively the same as the root reducer.
 */
export const contextSagas = [
	//TODO: fetchSubjectsTakenNeeds looking at for UI UX
	//Flows trigger by specific actions
	//takeLatest('context/setAppContextSuccess', fetchSubjectsTakenFlow),
	//takeLatest('context/changePrimaryGradepoint', fetchSubjectsTakenFlow),

	//TODO: This work is not yet needed, its for the UI UX but has broken mats, I am commenting this out until its needed
	//A proper action needs to be created to trigger this flow
	//takeLatest('homepage/setAgreementData', fetchSubjectsTakenFlow),

	takeLatest('context/triggerSchoolsInGroup', schoolsInGroupFlow),
	takeLatest('context/retriggerSingleSchoolPerformanceMeasures', schoolsInGroupFlow),
];
