import { cancel, put, retry, select } from 'redux-saga/effects';
import { sagaFlowConfigurations } from '../../../../../../constants';
import { getSummaryKpiDataBySchoolId } from '../../../../../../api/groupsAPI';
import { SagaIterator } from 'redux-saga';
import { getActiveChildSchool, getGroupAccessId } from 'features/app/redux/context';
import { translate } from '../../../../../../utils/locale';
import {
	setComponentLoading,
	setComponentErrors,
	setKs4HasBeenChecked,
	setSingleSchoolKpiSummarySuccess,
} from 'features/groups/analysis/redux/ks5PerformanceMeasures/singleSchool/slice';
import { getKs4HasBeenChecked } from 'features/groups/analysis/redux/ks5PerformanceMeasures/singleSchool/selectors';
import { SingleSchoolKpiSummary } from 'features/groups/analysis/redux/types/singleSchoolKpiSummary';
import { getGroupAccessRecords } from 'features/groups/setup/redux';

/**
 * Get the summary kpi tile data
 * @returns {SagaIterator<void>}
 */
export function* getSummaryKpiTileFlow(): SagaIterator<void> {
	//Set the loading state to true
	yield put(setComponentLoading({ component: 'summaryTile', value: true }));

	//reset the error state
	yield put(setComponentErrors({ component: 'summaryTile', value: undefined }));

	//get the school id
	const schoolId = yield select(getActiveChildSchool);

	if (!schoolId) {
		//bail out of the saga
		yield cancel();
	}

	//Check to see if the group access id is in the store
	const groupAccessId = yield select(getGroupAccessId) ?? 0;

	//if the group access id is 0, cancel the saga
	if (groupAccessId == 0) {
		//Log the error
		yield put(
			setComponentErrors({
				component: 'summaryTile',
				value: translate('groups.ks5Pm.common.NO_ACCESS_ID') as string,
			})
		);

		//Set the loading state to false
		yield put(setComponentLoading({ component: 'summaryTile', value: false }));

		//Bail out of the saga
		yield cancel();
	}

	//get the access records (available key stages)
	const accessRecords = yield select(getGroupAccessRecords);

	//check the access record for the the current group access id for KS4
	const isKs4 = accessRecords?.find((x) => x.id === groupAccessId).examLevel === 32;

	//get the state of KS4 from the store
	const ks4HasBeenChecked = yield select(getKs4HasBeenChecked);

	//if the access record is for KS4, check to see if the state of KS4 has already been set
	if (isKs4) {
		//if the state of KS4 has not been set, set it to true
		if (!ks4HasBeenChecked) {
			//set the state of KS4 to true
			yield put(setKs4HasBeenChecked(true));
		}
		//set the component loading state to false
		yield put(setComponentLoading({ component: 'summaryTile', value: false }));
		//error
		yield cancel();
	}
	//clear the state of KS4
	yield put(setKs4HasBeenChecked(false));

	const response: ResponseBuilder<SingleSchoolKpiSummary.Tile[]> = yield retry(
		sagaFlowConfigurations.apiRetry,
		sagaFlowConfigurations.milliSeconds,
		getSummaryKpiDataBySchoolId,
		groupAccessId,
		schoolId.childSchoolId
	);

	//If there is no data from the api, cancel the saga
	if (!response?.responseObject || response?.hasError) {
		//Set the error state
		yield put(
			setComponentErrors({
				component: 'summaryTile',
				value: translate('groups.ks5Pm.common.MESSAGE') as string,
			})
		);

		//Set the loading state to false
		yield put(setComponentLoading({ component: 'summaryTile', value: false }));

		//Bail out of the saga
		yield cancel();
	}

	// put the data into redux
	yield put(setSingleSchoolKpiSummarySuccess(response.responseObject));

	//Set the loading state to false
	yield put(setComponentLoading({ component: 'summaryTile', value: false }));
	//Bail out of the saga
	yield cancel();
}
