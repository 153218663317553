import * as React from 'react';
import { FunctionComponent, useState, useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { matchPath } from 'react-router';
import { useSelector } from 'react-redux';
import { getSubjectsList, getTeachingSetsList, getTeachersList } from './redux';
import { PrimaryNavHome, PrimaryNavDDWrapper, PrimaryNavMenuItemWrapper } from './components';

import {
	getClient,
	getClientCountry,
	getUserRoles,
	isLoading as ContextLoading,
	getSchoolsInGroup,
	getActiveChildSchool,
} from 'features/app/redux/context';
import { isLoading as ReportsLoading } from 'features/reports/redux/';
import { isLoading as MonitoringLoading } from 'features/monitoring/redux/selector';
import { isLoading as AccountsLoading } from 'features/manageAccount/redux/accounts';
import { isLoading as UserLoading } from 'features/manageUsers/redux/users';
import { isLoading as OrgLoading } from 'features/manageUsers/redux/organisationManagement';
import { isLoading as SubjectLoading } from 'features/subjectPage/redux/';
import { isLoading as SPOLoading } from 'features/spo/redux';
import { getFeature } from 'features/app/redux/features';
import { selectedClient } from 'features/reports/redux/MAT';
import { isLoading as StrategicAnalysisLoading } from 'features/strategicAnalysis/redux/';

import {
	connectRoot,
	connectSubjectPage,
	connectMonitoring,
	connectReports,
	connectSettings,
	connectStudents,
	connectPerformanceMetrics,
	connectStrategicAnalysis,
	summitRoot,
	summitReports,
} from './routes';

import {
	allRoutes,
	getDropdownMenus,
	getLink,
	getBackLink,
	getMenuItems,
	handleLinksColours,
	primaryNavHeight,
	shouldShowDropdown,
	shouldShowSlash,
	handleNavTrim,
} from '../../utils/primaryNavigation';

import { FlexWithRef, Link, PrimaryNavStaticLink } from 'basecamp';
import { useWindowSize } from '../../utils/hooks';
import { getSelectedSubject } from 'features/groups/analysis/redux';
import { connectSingleSchoolsInMats } from './routes/singleSchoolsInMats';

type Props = {
	dataTest?: string;
	welcomeText?: string;
	loadingText?: string;
};

const PrimaryNavigation: FunctionComponent<Props> = ({ dataTest, welcomeText, loadingText }) => {
	/**
	 ** Handle state
	 */
	const [showMenu, setShowMenu] = useState<number | null>(null);
	const [showBackIndicator, setShowBackIndicator] = useState<number | null>(null);
	const [backIndex, setBackIndex] = useState<number | null>(null);
	const [idToName, setIdToName] = useState<string>('');

	/**
	 ** Handle route matching
	 */
	const {
		params,
		url,
		path,
	}: { params: PrimaryNavigation.RouteParams; url: string; path: string } = useRouteMatch();
	const getParamKeys = Object.keys(params).filter((param) => param !== 'udftype');

	/**
	 * Hide PM Drop down for Welsh Clients
	 */
	//TODO: Revisit when more time
	const clientCountry = useSelector(getClientCountry);

	const isClientWelshSchool = clientCountry === 1;

	/**
	 ** Get data from state
	 */
	const subject = useSelector(getSubjectsList(params));
	const teachingSet = useSelector(getTeachingSetsList(params));
	const teacher = useSelector(getTeachersList(params));
	const client = useSelector(getClient);
	const userRoles = useSelector(getUserRoles);
	const hasGroupAccess = client?.hasGroupAccess;
	// Loading states used to disabled the links while loading data
	const contextLoading = useSelector(ContextLoading);
	const reportsLoading = useSelector(ReportsLoading);
	const monitoringLoading = useSelector(MonitoringLoading);
	const accountsLoading = useSelector(AccountsLoading);
	const usersLoading = useSelector(UserLoading);
	const orgLoading = useSelector(OrgLoading);
	const subjectLoading = useSelector(SubjectLoading);
	const spoLoading = useSelector(SPOLoading);
	const SAVE_REPORTS_ENABLED = useSelector(getFeature('subjects_page_save_reports'));
	const strategicAnalysisLoading = useSelector(StrategicAnalysisLoading);
	// Feature flagged KS4 Subject Pages
	const canViewKS4SubjectPages: boolean = useSelector(getFeature('ks4_subject_pages'));
	const canViewKS4PerfMeasures: boolean = useSelector(getFeature('ks4_perf_measures'));
	const canViewKS4PerfMeasuresSpo: boolean =
		useSelector(getFeature('ks4_perf_measures_spo')) && !isClientWelshSchool;
	const canViewP8Measures = useSelector(getFeature('ks4_progress_8_spo')) && !isClientWelshSchool;
	const canViewProgress8Measures: boolean = useSelector(getFeature('ks4_progress_8'));
	const canViewALevelPerfMeasures: boolean = useSelector(getFeature('ks5_a_level_perf_measures'));
	const canViewStrategicAnalysis: boolean = useSelector(getFeature('strategic_analysis'));
	const canViewOldStrategicAnalysis: boolean = useSelector(getFeature('strategic_analysis_old'));
	const canViewIBPerfMeasures: boolean = useSelector(getFeature('ks5_ib_perf_measures'));

	//PMs DropDown selectors
	const isSummitPmFeatureFlagEnabled = useSelector(getFeature('summit_ks5'));
	const singleSchoolPerformanceMeasures = useSelector(getSchoolsInGroup);
	const activeSchoolInGroup = useSelector(getActiveChildSchool);

	//can view new subjectArea Attainment page
	const canViewSubjectAreaAttainmentPage: boolean = useSelector(
		getFeature('subject_area_attainment_profile')
	);

	// Allow user to navigate on the following route params
	const backLinks = ['subject', 'subjectId', 'builderId'];
	useEffect(() => {
		getParamKeys.includes('udfvalue') ||
		(params.reportsPage === 'school-and-college-reports' && getParamKeys.includes('groupReportsId'))
			? setBackIndex(2)
			: getParamKeys.some((paramKey) => backLinks.includes(paramKey))
			? setBackIndex(1)
			: setBackIndex(null);
	}, [getParamKeys]);

	const hasBackLink = (i: number): boolean => Boolean(backIndex && i < backIndex);

	/**
	 ** Set primary nav ref
	 * This is used to obtain the width of the nav relative to the screen
	 * If the nav is larger than the available screen width trim the qual type / subject / teaching set name(s)
	 */
	const primaryNav = useRef<HTMLDivElement>(null);
	const { width } = useWindowSize();

	/**
	 ** Gets an array of dropdowns to display from the route base and route params
	 * E.g ['subjects', 'subject', 'subjectPage']
	 */
	const matchRoute = allRoutes.find((r) => {
		return matchPath(url, {
			path: r.path,
			exact: true,
			strict: false,
		});
	});

	/**
	 ** Map route params to dropdown menus
	 */
	// Connect
	const connectDropdowns: {
		[key in PrimaryNavigation.ParamKeys]?: PrimaryNavigation.Route[];
	} = {
		root: connectRoot(
			path,
			contextLoading,
			canViewKS4PerfMeasures,
			canViewALevelPerfMeasures,
			canViewStrategicAnalysis,
			canViewOldStrategicAnalysis,
			canViewIBPerfMeasures
		),
		// Subject Page
		subject: subject,
		teachingset: teachingSet,
		teacher: teacher,
		subjectPage: connectSubjectPage(
			params,
			subjectLoading,
			SAVE_REPORTS_ENABLED,
			canViewKS4SubjectPages,
			canViewSubjectAreaAttainmentPage
		),
		// Monitoring
		monitoringPage: connectMonitoring(params, monitoringLoading),
		// settings
		settingsRoot: connectSettings(
			path,
			accountsLoading,
			usersLoading,
			orgLoading,
			userRoles,
			hasGroupAccess
		),
		// Reports
		reportsPage: connectReports(params, reportsLoading),
		// SPO
		studentPage: connectStudents(params, spoLoading, canViewKS4PerfMeasuresSpo, canViewP8Measures),
		//PerformanceMetrics
		performanceMetrics: connectPerformanceMetrics(
			params,
			false,
			canViewKS4PerfMeasures,
			canViewProgress8Measures
		),
		// Strategic Overview
		strategicAnalysisPage: connectStrategicAnalysis(
			params,
			strategicAnalysisLoading,
			canViewStrategicAnalysis
		),
	};
	// Remove hidden dropdown items
	const filteredConnectDropdowns = Object.entries(connectDropdowns).reduce((acc, [key, val]) => {
		return { ...acc, [key]: !!val ? val.filter((x) => x.isVisible) : [] };
	}, {});

	// Summit
	const summitDropdowns: {
		[key in PrimaryNavigation.ParamKeys]?: PrimaryNavigation.Route[];
	} = {
		// root
		groupsRoot: summitRoot(path, isSummitPmFeatureFlagEnabled, activeSchoolInGroup),
		// reports
		groupsReports: summitReports(params, reportsLoading),
		// subjects
		groupsSubjects: summitRoot(path, isSummitPmFeatureFlagEnabled, activeSchoolInGroup),
		// settings
		settingsRoot: connectSettings(
			path,
			accountsLoading,
			usersLoading,
			orgLoading,
			userRoles,
			hasGroupAccess
		),
		// Single Schools in MATs
		groupKs5Pms: connectSingleSchoolsInMats(params, singleSchoolPerformanceMeasures),
	};

	const filteredSummitDropdowns = Object.entries(summitDropdowns).reduce((acc, [key, val]) => {
		return { ...acc, [key]: !!val ? val.filter((x) => x.isVisible) : [] };
	}, {});

	/**
	 * Handle params that have ids instead of text values
	 * e.g Group Reports id to school name
	 * e.g Group Subject id to subject name
	 */
	const reportsSelectedClient = useSelector(selectedClient);
	const summitSelectedSubject = useSelector(getSelectedSubject);

	useEffect(() => {
		if (reportsSelectedClient) {
			return setIdToName(reportsSelectedClient.name);
		}

		if (!!summitSelectedSubject) {
			return setIdToName(summitSelectedSubject);
		}

		setIdToName('');
	}, [reportsSelectedClient, summitSelectedSubject]);

	/**
	 ** Get the static menu items and dropdown menus
	 */
	const getDropdowns = hasGroupAccess ? filteredSummitDropdowns : filteredConnectDropdowns;
	const menuItems = getMenuItems(matchRoute, params, handleNavTrim(primaryNav), idToName);
	const dropdownMenus = getDropdownMenus(matchRoute, params, getDropdowns);

	/**
	 ** Render Component
	 */
	return (
		// Wrapper
		// TODO - Fix types for FlexWithRef component in Basecamp
		// @ts-ignore
		<FlexWithRef
			ref={primaryNav}
			setAs="row"
			withAlign="center"
			flexShrink={0}
			height={primaryNavHeight}
		>
			{/* Nav Home */}
			<PrimaryNavHome
				dataTest={dataTest}
				welcomeText={welcomeText}
				loadingText={loadingText}
				height={primaryNavHeight}
			/>
			{!welcomeText &&
				!loadingText &&
				menuItems.map((item, i: number) => {
					const hasDropdown = dropdownMenus && dropdownMenus[i]?.length >= 1;
					return (
						// Item Wrapper
						<PrimaryNavMenuItemWrapper
							key={i}
							onMouseEnter={() => setShowMenu(i)}
							onMouseLeave={() => setShowMenu(null)}
							height={primaryNavHeight}
						>
							{/* Static Link */}
							<PrimaryNavStaticLink
								fontSize={!!width && width < 1024 ? 5 : 6}
								hoverColor={hasBackLink(i) ? 'UI.accent[5]' : ''}
								linkColor={handleLinksColours(i, getParamKeys)}
								shouldShowSlash={shouldShowSlash(i, getParamKeys)}
								withInternalUrl={hasBackLink(i) ? getBackLink(i, url, hasGroupAccess) : ''}
								zIndex={shouldShowDropdown(i, showMenu, dropdownMenus) && 503}
								onMouseEnter={() => setShowBackIndicator(i)}
								onMouseLeave={() => setShowBackIndicator(null)}
								hasDropdown={hasDropdown}
								dropdownIsOpen={shouldShowDropdown(i, showMenu, dropdownMenus)}
								useCustomCursor={hasBackLink(i) && i === showBackIndicator}
							>
								{item}
							</PrimaryNavStaticLink>

							{/* Dropdown menu */}
							{shouldShowDropdown(i, showMenu, dropdownMenus) && (
								// Dropdown wrapper
								<PrimaryNavDDWrapper
									height={primaryNavHeight}
									key={i}
									index={i}
									paramKeys={getParamKeys}
								>
									{hasDropdown &&
										dropdownMenus[i].map((menu, j: number) => {
											return (
												// Dropdown link
												<Link
													setAs="primaryNavDDLink"
													withType="primaryNavDDLink"
													withInternalUrl={getLink(
														i,
														hasBackLink(i),
														url,
														params,
														menu,
														hasGroupAccess
													)}
													key={j}
													disabled={menu.isDisabled}
												>
													{menu.name}
												</Link>
											);
										})}
								</PrimaryNavDDWrapper>
							)}
						</PrimaryNavMenuItemWrapper>
					);
				})}
		</FlexWithRef>
	);
};

export default React.memo(PrimaryNavigation);
