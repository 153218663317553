import { translate } from '../../../utils/locale';
import { Context } from '../../../features/app/types/context';
import { lazy } from 'react';
const isVisible = (p: string, param: string): boolean => param !== p;

/**
 ** Connect Routes
 */
export const connectSingleSchoolsInMats: (
	params: { [key: string]: string },
	schoolsInGroup: Context.ArrayOfSchools[]
) => PrimaryNavigation.Route[] = (params, schoolsInGroup) => {
	return schoolsInGroup?.map((school) => {
		return {
			name: school.childSchoolName,
			path: `/groups/ks5pm/${encodeURIComponent(school.childSchoolName)}`,
			isVisible: true,
			type: 'groupKs5PmsSchools',
		};
	});
};
