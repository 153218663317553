import { SagaIterator } from 'redux-saga';
import { cancel, retry, put, select } from 'redux-saga/effects';
import { sagaFlowConfigurations } from '../../../../../src/constants';
import { getTrends } from '../../../../api/groupsAPI';
import { setAnalysisTrends, setAnalysisErrorMessage } from 'features/groups/analysis/redux';
import {
	getClient,
	getGroupAccessId,
	hasGroupAccess,
	retriggerSingleSchoolPerformanceMeasures,
	triggerMatTrendSelectorSaga,
} from 'features/app/redux/context';
import { triggerGpHandler } from 'features/groups/analysis/redux/ks5PerformanceMeasures/slice';

/**
 * Saga which fetches the trends for the selected group and key stage
 */
export function* matTrendSelectorFlow(): SagaIterator<void> {
	//get the group access id
	const groupAccessId = yield select(getGroupAccessId) ?? 0;

	//Is the current account a MAT account
	const isMat = yield select(hasGroupAccess);
	//get the client
	const client = yield select(getClient);
	//if there is no client, trigger the saga again
	if (!client) {
		//trigger the saga again
		yield put(triggerMatTrendSelectorSaga());
		//bail
		yield cancel();
	}

	//if there is no group access, bail
	if (!client.hasGroupAccess) {
		//bail
		yield cancel();
	}

	//The account is not a MAT
	if (!isMat) {
		//bail out of the saga
		yield cancel();
	}

	//The account is a Mat but something is wrong with the group access id
	if (groupAccessId === 0) {
		//bail out of the saga
		yield cancel();
	}

	//call the api
	const res = yield retry(
		sagaFlowConfigurations.apiRetry,
		sagaFlowConfigurations.milliSeconds,
		getTrends
	);

	if (res.HasError) {
		//if there is an error, set the error message
		yield put(setAnalysisErrorMessage(res.Errors[0].message));
		//bail
		yield cancel();
	}
	if (res.ResponseObject !== undefined && res.ResponseObject.length === 0) {
		yield put(setAnalysisErrorMessage('There are no trends for this group and key stage'));
		yield cancel();
	}

	//set the trends
	yield put(setAnalysisTrends(res.ResponseObject));

	//at this point the trend has definitely been set so it is sage to trigger the summit gp set
	yield put(triggerGpHandler);
	//recall the SchoolsInGroupSaga
	yield put(retriggerSingleSchoolPerformanceMeasures);

	//bail
	yield cancel();
}
