import { cancel, put, retry, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
	setComponentLoading,
	setKs4HasBeenChecked,
	setComponentErrors,
} from '../../ks5PerformanceMeasures/singleSchool/slice';
import { getGraphDataForGraphId } from '../../../../../../sharedSelectors';
import { sagaFlowConfigurations } from '../../../../../../constants';
import { getRawAttainmentGraphDataBySchoolId } from '../../../../../../api/groupsAPI';
import { getActiveChildSchool, getGroupAccessId } from 'features/app/redux/context';
import { setMainGraphDataSuccess } from '../../ks5PerformanceMeasures/singleSchool/slice';
import { getGroupAccessRecords } from 'features/groups/setup/redux';
import { getKs4HasBeenChecked } from '../../ks5PerformanceMeasures/singleSchool/selectors';
import { translate } from '../../../../../../utils/locale';
/**
 * Get the average points score graph data
 * @returns {SagaIterator<void>}
 */
export function* getRawAttainmentGraphFlow(): SagaIterator<void> {
	//get the school id
	const schoolId = yield select(getActiveChildSchool);

	if (!schoolId) {
		//bail out of the saga
		yield cancel();
	}

	//get the data from the store
	const initialData = yield select(
		getGraphDataForGraphId(
			'matSingleSchoolPerformanceMeasures',
			`0-${schoolId.childSchoolId},RawAttainmentGraph`
		)
	);

	//set the component loading state to true
	yield put(setComponentLoading({ component: 'rawAttainmentGraph', value: true }));

	//clear the errors for this component
	yield put(setComponentErrors({ component: 'rawAttainmentGraph', value: undefined }));

	//get the group access id
	const groupAccessId = yield select(getGroupAccessId);

	//check to see if the data is already in the store
	if (initialData !== undefined) {
		//set the component loading state to false
		yield put(setComponentLoading({ component: 'rawAttainmentGraph', value: false }));
		//bail out of the saga
		yield cancel();
	}

	//if the group access id is 0, bail out of the saga
	if (groupAccessId === 0) {
		//set the errors for this component
		yield put(
			setComponentErrors({
				component: 'rawAttainmentGraph',
				value: translate('groups.ks5Pm.common.NO_ACCESS_ID') as string,
			})
		);
		//set the component loading state to false
		yield put(setComponentLoading({ component: 'rawAttainmentGraph', value: false }));
		//bail out of the saga
		yield cancel();
	}

	//get the access records (available key stages)
	const accessRecords = yield select(getGroupAccessRecords);

	//check the access record for the the current group access id for KS4
	const isKs4 = accessRecords?.find((x) => x.id === groupAccessId).examLevel === 32;

	//get the state of KS4 from the store
	const ks4HasBeenChecked = yield select(getKs4HasBeenChecked);

	//if the access record is for KS4, check to see if the state of KS4 has already been set
	if (isKs4) {
		if (!ks4HasBeenChecked) {
			yield put(setKs4HasBeenChecked(true));
		}
		//set the component loading state to false
		yield put(setComponentLoading({ component: 'rawAttainmentGraph', value: false }));
		//bail out of the saga
		yield cancel();
	}
	//clear the state of KS4
	yield put(setKs4HasBeenChecked(false));

	//get the data from the api
	const rawAttainmentGraphData = yield retry(
		sagaFlowConfigurations.apiRetry,
		sagaFlowConfigurations.milliSeconds,
		getRawAttainmentGraphDataBySchoolId,
		groupAccessId,
		schoolId.childSchoolId
	);

	//if there is an error, set the errors for this component
	if (rawAttainmentGraphData.hasError) {
		//set the errors for this component
		yield put(
			setComponentErrors({
				component: 'rawAttainmentGraph',
				value: translate('groups.ks5Pm.common.MESSAGE') as string,
			})
		);
		//set the component loading state to false
		yield put(setComponentLoading({ component: 'rawAttainmentGraph', value: false }));
		//bail out of the saga
		yield cancel();
	}

	//set the data in the store
	yield put(setMainGraphDataSuccess(rawAttainmentGraphData.responseObject));

	//set the component loading state to false
	yield put(setComponentLoading({ component: 'rawAttainmentGraph', value: false }));

	//Bail out of the saga
	yield cancel();
}
