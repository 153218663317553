import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: Homepage.State = {
	loading: false,
	errorMessage: null,
	agreements: null,
};

/**
 ** Subjects slice
 */
const homepageSlice = createSlice({
	name: 'homepage',
	initialState,
	reducers: {
		setLoading(state: Homepage.State) {
			return {
				...state,
				loading: true,
				errorMessage: null,
			};
		},
		setErrorMessage(state: Homepage.State, action: PayloadAction<string>) {
			return {
				...state,
				loading: false,
				errorMessage: action.payload,
				data: null,
			};
		},
		clearErrorMessage(state: Homepage.State) {
			return {
				...state,
				loading: false,
				errorMessage: null,
			};
		},
		setAgreementData(state: Homepage.State, action: PayloadAction<Homepage.Agreement[]>) {
			return {
				...state,
				loading: false,
				errorMessage: null,
				agreements: action.payload,
			};
		},
		clearSubscriptionMessage(state: Homepage.State) {
			return {
				...state,
				loading: false,
				agreements: null,
			};
		},
		clearAgreements(state: Homepage.State) {
			return {
				...state,
				agreements: null,
			};
		},
	},
});

/**
 ** Export Reducers
 */
export default homepageSlice.reducer;

/**
 ** Export Actions
 */
export const {
	setLoading,
	setErrorMessage,
	clearErrorMessage,
	clearSubscriptionMessage,
	setAgreementData,
	clearAgreements,
} = homepageSlice.actions;
